var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('h1', {
    staticClass: "font-bold text-xl"
  }, [_vm._v(_vm._s(_vm.currentPath))]), _c('nav', {
    staticClass: "flex mb-5",
    class: [_vm.currentPath ? 'mt-2' : '']
  }, [_c('ol', {
    staticClass: "flex flex-wrap"
  }, [_vm.list.length > 0 ? _c('li', [_c('span', {
    staticClass: "text-yellow cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.changePath('HOME');
      }
    }
  }, [_vm._v(" File Gallery ")]), _c('Chevron', {
    staticClass: "mx-3",
    attrs: {
      "color": "#3b82f6",
      "height": "14"
    }
  })], 1) : _vm._e(), _vm._l(_vm.list, function (route, index) {
    return _c('li', {
      key: index,
      staticClass: "inline-flex items-center"
    }, [_c('span', {
      class: [_vm.isLast(index) ? 'text-yellow cursor-pointer' : 'text-neutral'],
      on: {
        "click": function click($event) {
          return _vm.changePath(route, index);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.truncateTitle(route.title)) + " ")]), _vm.isLast(index) ? _c('Chevron', {
      staticClass: "mx-3",
      attrs: {
        "color": "#3b82f6",
        "height": "14"
      }
    }) : _vm._e()], 1);
  })], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }