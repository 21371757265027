<template>
  <div class="">
    <h1 class="font-bold text-xl">{{ currentPath }}</h1>
    <nav class="flex mb-5" :class="[currentPath ? 'mt-2' : '']">
      <ol class="flex flex-wrap">
        <li v-if="list.length > 0">
          <span class="text-yellow cursor-pointer" @click="changePath('HOME')">
            File Gallery
          </span>
          <Chevron color="#3b82f6" height="14" class="mx-3" />
        </li>
        <li class="inline-flex items-center" :key="index" v-for="(route, index) in list">
          <span :class="[isLast(index) ? 'text-yellow cursor-pointer' : 'text-neutral']" @click="changePath(route, index)">
            {{ truncateTitle(route.title) }}
          </span>
          <Chevron color="#3b82f6" height="14" class="mx-3" v-if="isLast(index)" />
        </li>
      </ol>
    </nav>
  </div>
</template>
  <script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      visibleOption: false,
      tabindex: 0
    }
  },
  computed: {
    currentPath() {
      return this.truncateTitle(this.list[this.list.length - 1]?.title)
    }
  },
  components: {
    EllipsisVertical: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/EllipsisVertical'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Chevron')
  },
  methods: {
    isLast(index) {
      return this.list.length - 1 !== index
    },
    truncateTitle(title) {
      if(!title) return ''
      if (title.length <= 25) return title
      return title.substring(0, 25) + '...'
    },
    changePath(path, index) {
      if (this.list.length === 1 || this.isLast(index)) {
        this.$emit('clickBreadCrumb', path, index)
      }
    }
  }
}
</script>
  <style lang="scss" scoped>
.rotated {
  transform: rotate(90deg);
}
.option {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}
</style>